import axios from "axios";

const config = {
  headers: {
    Authorization: `Bearer 1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MjU2MDY1OTUsInN1YiI6IjE3MSJ9.j12vJAzEuAtKk67p_EqlT-h2YeBH3yPxWqSEY8gPIFk`,
  },
};

class CompanyAPI {
  async getCompany(id) {
    const myCompany = await axios({
      method: "get",
      url: "/api/v1/company/" + id,
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    return myCompany;
  }

  async getCompanyOrder(id) {
    const bodyParameters = {
      key: "",
    };

    const myCompany = axios
      .get("/api/v1/company/" + id, config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    const dates = ["8/30/2021", "9/1/2021", "9/2/2021", "9/3/2021", "9/4/2021"];
    myCompany.dates = dates;
    return myCompany;
  }

  async updateCompany(company, jwt) {
    const myCompany = axios
      .put("/api/v1/company", company, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        return Promise.reject(error);
      });

    return myCompany;
  }
}

export const companyApi = new CompanyAPI();
