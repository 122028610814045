import {
  Box, Container, Dialog, IconButton, Slide, Typography
} from "@material-ui/core";
import React from "react";
import XIcon from "../../../icons/X";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageModel = (props) => {
  const { open, handleClose, ...rest } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          p: 3,
        }}
      >
        <Container maxWidth="sm">
          <Box sx={{ mr: 1 }} align="right">
            <IconButton onClick={handleClose}>
              <XIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography sx={{ mt: -1 }} color="textPrimary" variant="h5">
            Call: 513-123-4567
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            variant="body2"
            sx={{ mt: 1, mb: 2 }}
          >
            Call our direct hotline above to have any immediate questions
            answered.
          </Typography>
        </Container>
      </Box>
    </Dialog>
  );
};

export default ImageModel;
