import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import MenuIcon from "../../icons/Menu";
import AccountPopover from "./AccountPopover";
// import Logo from "../Logo";
import useAuth from "../../hooks/useAuth";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: "background.paper",
    color: "text.secondary",
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { user } = useAuth();
  const issm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  console.log(user);
  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        {!issm && (
          <RouterLink to="/">
            {/* <Logo
              sx={{
                display: {
                  lg: "inline",
                  xs: "none",
                },
                height: 40,
                width: 40,
              }}
            /> */}
          </RouterLink>
        )}

        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <Typography
            color="white"
            sx={{
              mb: 0.5,
            }}
            variant="subtitle2"
          >
            {user.email}
          </Typography>
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
