import axios from "axios";

class AuthAPI {
  async login(email, password) {
    var bodyFormData = new FormData();
    bodyFormData.append("username", email);
    bodyFormData.append("password", password);

    const response = await axios({
      method: "post",
      url: "/api/v1/login/access-token",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw error;
        return error;
      });

    return response;
  }

  async me(token) {
    const response = await axios({
      method: "get",
      url: "/api/v1/users/me",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw error;
        return error;
      });

    return response;
  }
}

export const authApi = new AuthAPI();
