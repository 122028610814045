import { useState } from "react";
import PropTypes from "prop-types";
import { experimentalStyled } from "@material-ui/core/styles";
import { Outlet } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
import { loadStripe } from "@stripe/stripe-js";

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
}));

const PublicLayout = ({ children }) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  return (
    <Elements stripe={stripePromise}>
      <MainLayoutRoot>{children || <Outlet />}</MainLayoutRoot>
    </Elements>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node,
};

export default PublicLayout;
