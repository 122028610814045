import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import CalendarIcon from "../../icons/Calendar";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
//import FolderOpenIcon from "../../icons/FolderOpen";
import Users from "../../icons/Users";
import {
  GroupWork,
  DirectionsBoat,
  AttachMoney,
  //LocalOffer,
  ListAlt,
} from "@material-ui/icons";
import UserIcon from "../../icons/User";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import HotlineModal from "../widgets/modals/HotlineModal";

const sections = [
  {
    title: "General",
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      // {
      //   title: "Revenue",
      //   path: "/dashboard",
      //   icon: <AttachMoney fontSize="small" />,
      // },
      {
        title: "Calendar",
        path: "/dashboard/calendar",
        icon: <CalendarIcon fontSize="small" />,
      },
      {
        title: "Account",
        path: "/dashboard/account",
        icon: <UserIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Management",
    items: [
      {
        title: "Orders",
        icon: <Users fontSize="small" />,
        path: "/dashboard/orders",
      },
      {
        title: "Water Craft",
        path: "/dashboard/products",
        icon: <DirectionsBoat fontSize="small" />,
      },
      {
        title: "Add Ons",
        path: "/dashboard/addons",
        icon: <GroupWork fontSize="small" />,
      },
      // {
      //   title: "Surge Pricing",
      //   path: "/dashboard/invoices",
      //   icon: <LocalOffer fontSize="small" />,
      //   path: "/dashboard/surgepricing",
      // },
      // {
      //   title: "Deck Hand Form",
      //   path: "/dashboard/deckhand",
      //   icon: <ListAlt fontSize="small" />,
      //   // children: [
      //   //   {
      //   //     title: "List",
      //   //     path: "/dashboard/addons",
      //   //   },
      //   //   {
      //   //     title: "Create",
      //   //     path: "/dashboard/addons/new",
      //   //   },
      //   // ],
      // },
      // {
      //   title: "Waivers",
      //   path: "/dashboard/invoices",
      //   icon: <FolderOpenIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: "List",
      //       path: "/dashboard/invoices",
      //     },
      //     {
      //       title: "Details",
      //       path: "/dashboard/invoices/1",
      //     },
      //   ],
      // },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const [open, setOpen] = useState(false);

  const handleOpen = (image) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.user.full_name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Button
            color="primary"
            onClick={handleOpen}
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
          >
            Call our Direct Hotline
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
        <HotlineModal open={open} handleClose={handleClose} />
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
      <HotlineModal open={open} handleClose={handleClose} />
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
