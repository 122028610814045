import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";

import { authApi } from "../__Api__/auth";
import { companyApi } from "../__Api__/companyApi";
import { S3Api } from "../__Api__/S3";
import { firebaseConfig } from "../config";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATE_AVATAR: (state) => ({ ...state }),
  UPDATE_USER: (state) => ({ ...state }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateAvatar: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        console.log(`Found access token ${accessToken}`)

        if (accessToken) {
          const user = await authApi.me(accessToken.access_token);
          const company = await companyApi.getCompany(user.company_id);
          const singedURL = await S3Api.downloadProfile(
            storage,
            company.img_url
          );

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user: {
                id: user.id,
                avatar: singedURL,
                email: user.email,
                company: company,
                user: user,
                bearer: accessToken.access_token,
              },
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const updateAvatar = async (user, profile) => {
    const newUser = user;

    newUser.avatar = profile;

    dispatch({
      type: "UPDATE_AVATAR",
      payload: {
        user: newUser,
      },
    });
  };

  const login = async (email, password) => {
    const accessToken = await authApi.login(email, password);

    const user = await authApi.me(accessToken.access_token);
    const company = await companyApi.getCompany(user.company_id);
    const singedURL = await S3Api.downloadProfile(storage, company.img_url);

    dispatch({
      type: "LOGIN",
      payload: {
        user: {
          id: user.id,
          avatar: singedURL,
          email: user.email,
          company: company,
          user: user,
          bearer: accessToken.access_token,
        },
      },
    });
  };

  const logout = async () => {
    // localStorage.removeItem("accessToken");
    console.log("NOT WIPING ACCESS TOKEN AFTER LOGGING OUT")
    dispatch({ type: "LOGOUT" });
  };

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const updateUser = async (user, profile) => {
    const newUser = user;

    newUser.user = profile;

    dispatch({
      type: "UPDATE_USER",
      payload: {
        user: newUser,
      },
    });
  };

  const getStorage = () => storage;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        register,
        updateAvatar,
        getStorage,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
