import { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { experimentalStyled } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
import { loadStripe } from "@stripe/stripe-js";
import OrderNavbar from "./OrderNavbar";
import { companyApi } from "../__Api__/companyApi";
import { S3Api } from "../__Api__/S3";
import useMounted from "../hooks/useMounted";
import useAuth from "../hooks/useAuth";

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
}));

const OrderLayout = ({ children }) => {
  const { getStorage } = useAuth();
  const [company, setCompany] = useState();
  const [companyImage, setCompanyImage] = useState();
  const { companyid } = useParams();
  const mounted = useMounted();

  const getCompany = useCallback(async () => {
    try {
      const data = await companyApi.getCompany(companyid);
      const image = await S3Api.downloadProfile(getStorage(), data.img_url);

      if (mounted.current) {
        setCompany(data);
        setCompanyImage(image);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCompany();
  }, []);

  if (!company) {
    return <CircularProgress />;
  }

  return (
    <Elements stripe={stripePromise}>
      <OrderNavbar companyImage={companyImage} company={company} />
      <MainLayoutRoot>{children || <Outlet />}</MainLayoutRoot>
    </Elements>
  );
};

OrderLayout.propTypes = {
  children: PropTypes.node,
};

export default OrderLayout;
