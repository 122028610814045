import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Box, Button, Divider, Toolbar } from "@material-ui/core";
import Logo from "./Logo";

const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "background.paper",
        color: "text.secondary",
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <RouterLink to="/">
          <Box sx={{mb:-1, mt:-1}}>
            <Logo
              sx={{
                display: {
                  xs: "inline",
                },
              }}
            />
          </Box>
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: "center",
            display: {
              xs: "flex",
            },
          }}
        >
          <Button
            color="primary"
            component="a"
            href="/dashboard"
            size="small"
            variant="contained"
          >
            Login
          </Button>
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
