import {
  ref,
  uploadBytes,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";
import { Storage } from "aws-amplify";

class S3API {
  async uploadProfile(storage, obj) {
    try {
      // try {
      //   const result = await Storage.put("account/" + obj.id, obj.file);

      //   return Promise.resolve(result);
      // } catch (err) {
      //   console.error("[Auth Api]: ", err);
      //   new Error("Internal server error");

      // }
      const profileRef = ref(storage, "account/" + obj.id);

      const result = await uploadBytes(profileRef, obj.file);
    } catch (err) {
      console.error("[Auth Api]: ", err);
      new Error("Internal server error");
    }
  }

  async uploadResource(storage, obj) {
    try {
      //   const result = await Storage.put("resource/" + obj.aws_id, obj.file);

      //   return result;
      // } catch (err) {
      //   console.error("[Auth Api]: ", err);
      //   new Error("Internal server error");
      // }

      const resourceRef = ref(storage, "resource/" + obj.aws_id);

      const result = await uploadBytes(resourceRef, obj.file);
    } catch (err) {
      console.error("[Auth Api]: ", err);
      new Error("Internal server error");
    }
  }

  async downloadProfile(storage, id) {
    try {
      // const signedURL = await Storage.get("account/" + id);
      // return Promise.resolve(signedURL);
      const profileRef = ref(storage, "account/" + id);

      const result = await getDownloadURL(profileRef);

      return result;
    } catch (err) {
      console.error("[Auth Api]: ", err);
      new Error("Internal server error");
    }
  }

  async deleteProfile(storage, id) {
    try {
      // const signedURL = await Storage.remove("account/" + id);

      const profileRef = ref(storage, "account/" + id);

      await deleteObject(profileRef);
    } catch (err) {
      console.error("[Auth Api]: ", err);
      new Error("Internal server error");
    }
  }

  async downloadResourceImage(storage, id) {
    try {
      // const signedURL = await Storage.get("resource/" + id);
      // return Promise.resolve(signedURL);
      const resourceRef = ref(storage, "resource/" + id);

      const result = await getDownloadURL(resourceRef);

      return result;
    } catch (err) {
      console.error("[Auth Api]: ", err);
      new Error("Internal server error");
    }
  }

  async deleteResource(storage, id) {
    try {
      // await Storage.remove("resource/" + id);
      const resourceRef = ref(storage, "resource/" + id);

      await deleteObject(resourceRef);
    } catch (err) {
      console.error("[Auth Api]: ", err);
      new Error("Internal server error");
    }
  }
}

export const S3Api = new S3API();
