import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";
import PublicLayout from "./components/PublicLayout";
import OrderLayout from "./components/OrderLayout";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/BoatLogin")));
const PasswordRecovery = Loadable(
  lazy(() => import("./pages/authentication/PasswordRecovery"))
);
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
);
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const VerifyCode = Loadable(
  lazy(() => import("./pages/authentication/VerifyCode"))
);

// Dashboard pages

const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));

const SurgePricing = Loadable(
  lazy(() => import("./pages/dashboard/surgePricing"))
);

const MainDashboard = Loadable(
  lazy(() => import("./pages/dashboard/Dashboard"))
);

const Schedule = Loadable(lazy(() => import("./pages/dashboard/Schedule")));

const CustomerDetails = Loadable(
  lazy(() => import("./pages/dashboard/CustomerDetails"))
);

const OrderDetails = Loadable(
  lazy(() => import("./pages/dashboard/BoatOrderDetails"))
);

const NewOrder = Loadable(lazy(() => import("./pages/dashboard/NewBoatOrder")));

const OrderList = Loadable(lazy(() => import("./pages/dashboard/OrderList")));

const BoatOrderList = Loadable(
  lazy(() => import("./pages/dashboard/BoatOrderList"))
);

const WaterCraftView = Loadable(
  lazy(() => import("./pages/dashboard/WaterCraftView"))
);

const WaterCraftNew = Loadable(
  lazy(() => import("./pages/dashboard/WaterCraftNew"))
);

const WaterCraftCreate = Loadable(
  lazy(() => import("./pages/dashboard/WaterCraftCreate"))
);

const WaterCraftList = Loadable(
  lazy(() => import("./pages/dashboard/WaterCraftList"))
);

const WaterCraftDetails = Loadable(
  lazy(() => import("./pages/dashboard/WaterCraftDetails"))
);

const AddOnCreate = Loadable(
  lazy(() => import("./pages/dashboard/AddOnCreate"))
);

const AddOnList = Loadable(lazy(() => import("./pages/dashboard/AddOnList")));

const DeckHandList = Loadable(lazy(() => import("./pages/dashboard/DeckHand")));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Public pages

const PublicOrderLanding = Loadable(lazy(() => import("./pages/PublicOrder")));
const OrderLanding = Loadable(
  lazy(() => import("./pages/GeneralOrderWrapper"))
);

// Other pages

const Contact = Loadable(lazy(() => import("./pages/Contact")));
const Home = Loadable(lazy(() => import("./pages/Home")));
const BoatHome = Loadable(lazy(() => import("./pages/BoatHome")));

// const Pricing = Loadable(lazy(() => import("./pages/Pricing")));

const IframeTest = Loadable(lazy(() => import("./pages/IframeTest")));

const Products = Loadable(lazy(() => import("./pages/shop/Products")));
const ProductDetails = Loadable(
  lazy(() => import("./pages/shop/ProductDetails"))
);
const Checkout = Loadable(lazy(() => import("./pages/shop/Checkout")));
const Cart = Loadable(lazy(() => import("./pages/shop/Cart")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <MainDashboard />,
      },
      {
        path: "account",
        element: <Account />,
      },

      {
        path: "calendar",
        element: <Schedule />,
      },
      {
        path: "surgepricing",
        element: <SurgePricing />,
      },
      {
        path: "orders",
        children: [
          {
            path: "",
            element: <BoatOrderList />,
          },
          // {
          //   path: "new/:companyid",
          //   element: <NewOrder />,
          // },
          {
            path: ":orderId",
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: "products",
        children: [
          {
            path: "",
            element: <WaterCraftList />,
          },
          {
            path: "new",
            element: <WaterCraftNew />,
          },
          {
            path: ":id/inventory",
            element: <WaterCraftView tab="inventory" />,
          },
          {
            path: ":id/pricing",
            element: <WaterCraftView tab="pricing" />,
          },
          {
            path: ":id/settings",
            element: <WaterCraftView tab="settings" />,
          },
        ],
      },
      {
        path: "addons",
        children: [
          {
            path: "",
            element: <AddOnList />,
          },
          {
            path: "new",
            element: <AddOnCreate />,
          },
          {
            path: "edit/:id",
            element: <AddOnCreate />,
          },
        ],
      },
      {
        path: "deckhand",
        children: [
          {
            path: "",
            element: <DeckHandList />,
          },
          {
            path: "view",
            element: <AddOnCreate />,
          },
        ],
      },
    ],
  },

  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <BoatHome />,
      },

      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "test",
        element: <IframeTest />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "p",
    element: <PublicLayout />,
    children: [
      {
        path: ":companyid",
        element: <OrderLanding isPublic={true} />,
      },
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: "shop",
    element: <OrderLayout />,
    children: [
      {
        path: ":companyid/products",
        element: <Products />,
      },
      {
        path: ":companyid/checkout",
        element: <Checkout />,
      },
      {
        path: ":companyid/:resourceid",
        element: <ProductDetails />,
      },
      {
        path: ":companyid/cart",
        element: <Cart />,
      },
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
    ],
  },
];

export default routes;
