import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Logo from "./Logo";

const MainNavbar = (props) => {
  const { companyImage, company } = props;

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "background.paper",
        color: "text.secondary",
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Box sx={{ mb: 0, mt: 1 }}>
          <img
            alt="Booking Anchor"
            src={companyImage}
            style={{
              zIndex: 20,
              height: 60,
              width: "auto",
            }}
          />
        </Box>
        <Box sx={{ m: 1, mt: 1 }}>
          <Typography variant="h6" color="textPrimary">
            {company.name}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
